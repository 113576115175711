<template>
  <div>
    <Adsense
      data-ad-client="ca-pub-8018925037718321"
      data-ad-slot="8588205727"
      data-ad-format="auto"
      :data-full-width-responsive="true"
      style="text-align: center"
    ></Adsense>
    <div class="content privacy">
      <p class="title is-3">NORMATIVA SULLA PRIVACY</p>
      <p>
        <strong>Informativa ai sensi dell'art. 13 del Codice della Privacy</strong>
      </p>
      <p>
        <b>Ai sensi dell'articolo 13 del codice della D.Lgs. 196/2003, vi rendiamo le seguenti informazioni.</b>
      </p>
      <p>Riteniamo che la privacy dei nostri visitatori sia estremamente importante. Questo documento descrive dettagliatamente i tipi di informazioni personali raccolti e registrati dal nostro sito e come essi vengano utilizzati.</p>
      <p class="title is-4">File di Registrazione (Log Files)</p>
      <p>Come molti altri siti web, il nostro utilizza file di log. Questi file registrano semplicemente i visitatori del sito - di solito una procedura standard delle aziende di hosting e dei servizi di analisi degli hosting.</p>
      <p>Le informazioni contenute nei file di registro comprendono indirizzi di protocollo Internet (IP), il tipo di browser, Internet Service Provider (ISP), informazioni come data e ora, pagine referral, pagine d'uscita ed entrata o il numero di clic.</p>
      <p>
        Queste informazioni vengono utilizzate per analizzare le tendenze, amministrare il sito, monitorare il movimento degli utenti sul sito e raccogliere informazioni demografiche. Gli indirizzi IP e le altre informazioni non sono collegate a informazioni personali che possono essere identificate, dunque
        <strong>tutti i dati sono raccolti in forma assolutamente anonima</strong>.
      </p>
      <div id="cookies">
        <p class="title is-4">Questo sito web utilizza i Cookies</p>
        <p>I cookies sono piccoli file di testo che vengono automaticamente posizionati sul PC del navigatore all’interno del browser. Essi contengono informazioni di base sulla navigazione in Internet e grazie al browser vengono riconosciuti ogni volta che l’utente visita il sito.</p>
        <p class="title is-4">Cookie Policy</p>Questo sito utilizza cookies, anche di terze parti, per migliorarne l’esperienza di navigazione, consentire a chi naviga di usufruire di eventuali servizi online e monitorare la navigazione nel sito.
        <p></p>
        <p class="title is-4">Come disabilitare i Cookies</p>
        <p>E’ possibile disabilitare i cookies direttamente dal browser utilizzato, accedendo alle impostazioni (preferenze oppure opzioni): questa scelta potrebbe limitare alcune funzionalità di navigazione del sito.</p>
        <p class="title is-4">Gestione dei Cookies</p>
        <p>I cookies utilizzati in questo sito possono rientrare nelle categorie descritte di seguito.</p>
        <ul>
          <!-- <li>
          <strong>Attività strettamente necessarie al funzionamento</strong>
          <br />Questi cookies hanno natura tecnica e permettono al sito di funzionare correttamente. Ad esempio, mantengono l’utente collegato durante la navigazione evitando che il sito richieda di collegarsi più volte per accedere alle pagine successive.
          </li>-->
          <li>
            <strong>
              Attività Statistiche e di Misurazione dell’audience (es: Google Analytics)
              <br />
            </strong>Questi cookie ci aiutano a capire, attraverso dati raccolti in forma anonima e aggregata, come gli utenti interagiscono con i siti internet fornendo informazioni relative alle sezioni visitate, il tempo trascorso sul sito, eventuali malfunzionamenti. Questo aiuta a migliorare la resa dei siti internet.
          </li>
          <li>
            Google Authentication Identity Provider, ai fini della sincronizzazione in Cloud (se attivata)
          </li>
        </ul>
      </div>
      <div id="fornitori">
        <p class="title is-4">Fornitori di terze parti</p>
        <p>I fornitori di terze parti, tra cui Google, utilizzano cookie per pubblicare annunci in base alle precedenti visite di un utente su questo sito.</p>
        <p>L'utilizzo dei cookie per la pubblicità consente a Google e ai suoi partner di pubblicare annunci per gli utenti di questo sito (e su altri siti) in base ai dati statistici raccolti su questo sito e sui siti web dei partner Google.</p>
        <p>
          Gli utenti possono scegliere di disattivare la pubblicità personalizzata, visitando la pagina
          <a
            href="https://www.google.com/settings/ads"
            target="_blank"
            title="Impostazioni annunci"
          >Impostazioni annunci</a>.
        </p>
        <p>Visitando la pagina www.aboutads.info potrai disattivare i cookies dei fornitori di terze parti.</p>
      </div>
      <p class="title is-4">Finalità del trattamento</p>
      <p>I dati possono essere raccolti per una o più delle seguenti finalità:</p>
      <ul>
        <li>fornire un servizio di reportistica</li>
        <li>sincronizzazione dei movimenti in modalità Cloud</li>
      </ul>
      <p class="title is-4">Modalità del trattamento</p>
      <p>I dati verranno trattati con le seguenti modalità:</p>
      <ul>
        <li>
          <strong>raccolta dati memorizzata direttamente sul browser dell'utente;</strong>
        </li>
        <li>
          sincronizzazione su account Google Drive dell'utente nella 'Application Data Folder' dedicata
        </li>
      </ul>
      <p class="title is-4">Natura obbligatoria</p>
      <p>Tutti i dati richiesti sono obbligatori.</p>
      <p class="title is-4">Diritti dell'interessato</p>
      <p>Ai sensi ai sensi dell'art. 7 (Diritto di accesso ai dati personali ed altri diritti) del Codice della Privacy, vi segnaliamo che i vostri diritti in ordine al trattamento dei dati sono:</p>
      <ul>
        <li>conoscere, mediante accesso gratuito l'esistenza di trattamenti di dati che possano riguardarvi;</li>
        <li>essere informati sulla natura e sulle finalità del trattamento</li>
        <li>ottenere a cura del titolare, senza ritardo:</li>
        <ul>
          <li>la conferma dell'esistenza o meno di dati personali che vi riguardano, anche se non ancora registrati, e la comunicazione in forma intellegibile dei medesimi dati e della loro origine, nonché della logica e delle finalità su cui si basa il trattamento; la richiesta può essere rinnovata, salva l'esistenza di giustificati motivi, con intervallo non minore di novanta giorni;</li>
          <li>la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati;</li>
          <li>l'aggiornamento, la rettifica ovvero, qualora vi abbia interesse, l'integrazione dei dati esistenti;</li>
          <li>opporvi in tutto o in parte per motivi legittimi al trattamento dei dati personali che vi riguardano ancorché pertinenti allo scopo della raccolta;</li>
        </ul>
      </ul>Per esercitare i diritti previsti all'art. 7 del Codice della Privacy, è sufficiente contattarci attraverso uno dei canali messi a disposizione.
      <p class="title is-4">Consenso</p>
      <p>Usando il nostro sito web, acconsenti alla nostra politica sulla privacy e accetti i suoi termini. Se desideri ulteriori informazioni o hai domande sulla nostra politica sulla privacy non esitare a contattarci.</p>
    </div>
    <Adsense
      data-ad-client="ca-pub-8018925037718321"
      data-ad-slot="4648960717"
      data-ad-format="auto"
      :data-full-width-responsive="true"
      style="text-align: center; margin-bottom: 20px"
    ></Adsense>
  </div>
</template>

<style scoped>
.privacy {
  text-align: justify;
  font-size: 0.85em;
  margin-bottom: 2em;
  padding-left: 4em;
  padding-right: 4em;
}
p {
  margin-top: 15px;
}
</style>