
.privacy[data-v-40b7829c] {
  text-align: justify;
  font-size: 0.85em;
  margin-bottom: 2em;
  padding-left: 4em;
  padding-right: 4em;
}
p[data-v-40b7829c] {
  margin-top: 15px;
}


/*# sourceMappingURL=chunk-7f3a95fc.a23155bd.css.map*/